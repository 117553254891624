<template>
  <a href="#content" class="stc button button--small" :aria-label="t('global.skip-to-content')">
    {{ t('global.skip-to-content') }}
  </a>
</template>

<script setup>
const { t } = useI18n();
</script>

<style lang="scss" scoped>
.stc {
  position: fixed;
  top: 30px;
  left: -999px;
  z-index: 10;
  transition: none !important;

  &:focus,
  &:active {
    left: 40px;
  }
}
</style>
